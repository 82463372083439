import React, {useEffect,useState} from 'react';
import { Slider } from '../components/slider';
import { Scroll_Butons } from '../components/scroll_butons';
import { Tabs_Sports } from '../components/tabs_sports.js';
import { Resultados } from '../components/resultados';
import { Servicios_Form_1 } from '../components/servicios_form_1';
import { Noticias_Form_3 } from '../components/noticias_form_3';
import { Loader } from '../components/loader';
export const Home = () => {
    const [time,setTime] =useState('d-none');
    const [loader,setLoader] =useState('d-block bg-white');
    useEffect(() => {
        const myTimeout = setTimeout(myGreeting, 5000);
        function myGreeting() {
            setTime("container-fluid");
            setLoader("d-none");
        }
      }, []);
    return (
        <div className="overflow-hidden">
            <Resultados />
            <div className="container-fluid mt-2"> 
                <Noticias_Form_3 />
            </div>
            <div className={time}>
                <div className="row g-10">
                    <div className="col-12">
                        <Scroll_Butons />
                    </div>
                    <div className="col-12">
                        <Tabs_Sports />
                    </div>
                </div>
            </div>
            <div className={loader}>
                <Loader/>
            </div>
            <Slider />
            <Servicios_Form_1 />
        </div>
    )
}
