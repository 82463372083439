import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.webp';
import logos from '../assets/img/logos.webp';

export default class header extends Component {
    render() {
        return (
            <div className='header'>
                <div className="row g-0">
                    <div className="col-lg-3 d-flex justify-content-center align-items-center pb-2">
                        <Link to="/home">
                            <img className="img-fluid" src={logo} alt="logo"></img>
                        </Link>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-center align-items-center px-lg-2">
                        <form action="javascript:void(0)" name="LoginForm">
                            <div class="row p-2 text-white textShadow">
                                <div class="col-4 text-center"><input id="userInputLog" class="form-control bg-white" name="username" type="text" placeholder="USER" aria-label="USER" /></div>
                                <div class="col-4 text-center"><input id="password" class="form-control bg-white" name="password" required="" type="password" placeholder="PASSWORD" aria-label="PASSWORD" /> <input name="idsite" type="hidden" value="1" /> <input name="BackEndUrl" type="hidden" value="https://sports.wagerdragon.com/" /> <script src="https://images.betimages.com/Betslip/js/quick.login.min.js" type="text/javascript"></script></div>
                                <div class="col-4 text-center"><button class="btn btn_login" type="submit">Login</button></div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center align-items-center p-2 p-md-0">
                        <img className='img-fluid' src={logos} alt="logos of sports"></img>
                    </div>
                </div>
            </div>
        )
    }
}
