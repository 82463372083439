import React, { useContext } from "react";
import DateTime from '../../node_modules/luxon/src/datetime.js'
import { Context } from "../store/appContext";

import { Tabs_Nfl } from '../tabs/nfl/tabs_nfl';
import { Tabs_Nba } from '../tabs/nba/tabs_nba';
import { Tabs_MLB } from '../tabs/mlb/tabs_mlb';
import { Tabs_NHL } from '../tabs/nhl/tabs_nhl';
import { Tabs_Nascar } from '../tabs/nascar/nascar_tabs';
import { Tabs_Moto_GP } from '../tabs/moto_gp/Tabs_Moto_gp';
import { Tabs_Box } from '../tabs/box/tabs_box';
import { Tabs_MMA } from '../tabs/mma/tabs_mma';
import { Tabs_Golf } from '../tabs/golf/tabs_golf';
export const Tabs_Sports = () => {
    const { store } = useContext(Context);
    const monthLux = DateTime.now().month;
    const yearLux = DateTime.now().year;
    let nfl_show;
    let nba_show;
    let mlb_show;
    let nhl_show;
    {
        store.nfl.map((item, index) => {
            let mes = item.date.slice(5, 7);
            let ano = item.date.slice(0, 4);
            if (mes == monthLux && ano == yearLux) {
                nfl_show = "accordion-collapse collapse show";
                nba_show = "accordion-collapse collapse";
                mlb_show = "accordion-collapse collapse";
                nhl_show = "accordion-collapse collapse";
            }
        })
    }
    {
        store.mlb.map((item, index) => {
            let mes = item.date.slice(5, 7);
            let ano = item.date.slice(0, 4);
            if (mes == monthLux && ano == yearLux) {
                nfl_show = "accordion-collapse collapse";
                nba_show = "accordion-collapse collapse";
                mlb_show = "accordion-collapse collapse show";
                nhl_show = "accordion-collapse collapse";
            }
        })
    }
    {
        store.nhl.map((item, index) => {
            let mes = item.date.slice(5, 7);
            let ano = item.date.slice(0, 4);
            if (mes == monthLux && ano == yearLux) {
                nfl_show = "accordion-collapse collapse";
                nba_show = "accordion-collapse collapse";
                mlb_show = "accordion-collapse collapse";
                nhl_show = "accordion-collapse collapse show";
            }
        })
    }
    {
        store.nba_games.map((item, index) => {
            let mes = item.date.slice(5, 7);
            let ano = item.date.slice(0, 4);
            if (mes == monthLux && ano == yearLux) {
                nfl_show = "accordion-collapse collapse";
                nba_show = "accordion-collapse collapse show";
                mlb_show = "accordion-collapse collapse ";
                nhl_show = "accordion-collapse collapse";
            }
        })
    }
    return (
        <div className='mt-3 mxH'>
            <div className="accordion" id="accordionExample">
                <div className="text-white">
                    <div id="NFL" className={nfl_show} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <Tabs_Nfl />
                    </div>
                </div>
                <div className="text-white">
                    <div id="NBA" className={nba_show} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <Tabs_Nba />
                    </div>
                </div>
                <div className="text-white">
                    <div id="MLB" className={mlb_show} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <Tabs_MLB />
                    </div>
                </div>
                <div className="text-white">
                    <div id="HOCKEY" className={nhl_show} aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <Tabs_NHL />
                    </div>
                </div>
                <div className="text-white">
                    <div id="NASCAR" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <Tabs_Nascar />
                    </div>
                </div>
                <div className="text-white">
                    <div id="MOTO_GP" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <Tabs_Moto_GP />
                    </div>
                </div>
                <div className="text-white">
                    <div id="BOX" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <Tabs_Box />
                    </div>
                </div>
                <div className="text-white">
                    <div id="MMA" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <Tabs_MMA />
                    </div>
                </div>
                <div className="text-white">
                    <div id="GOLF" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <Tabs_Golf />
                    </div>
                </div>
                <div className="text-white">
                    <div id="close" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    </div>
                </div>
            </div>
        </div>
    )
}
